.heading {
  text-align: center;
  font-family: "DM Serif Display", sans-serif !important;
  font-size: 2rem;
}

.subscribeNow {
  font-weight: normal;
  font-size: 1.2rem;
}

.inbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 5vh;
}

.formWrapper {
  padding: 5px;
  background-color: #d3ecd6;
  border-radius: 10px;
  width: 45vw;
}

.politicalRemastered {
  width: 30vw;
  height: auto;
  opacity: 0.15;
  position: absolute;
  left: -12%;
  z-index: -1;
}

.paranormalRemastered {
  @extend .politicalRemastered;
  left: unset;
  bottom: -20%;
  right: -6%;
}

.lIghthousesc4 {
  width: 20vw;
  height: auto;
  position: relative;
  border-radius: 5%;
  top: 15%;
  display: block;
}

.qrCode{
  width: 10vw;
  height: auto;
  position: relative;
  top: 15%;
}

.qrText {
  position: relative;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: 'DM Sans', sans-serif !important;
  top: 20%;
}

.qrcodeSection {
  margin-top: 0 !important;
}


@media only screen and (max-width: 1000px) {
  .heading {
    font-size: 1.5rem;
  }

    .formWrapper {
      width: 50vw;
      padding: 0;
    }

  .qrcodeSection {
    margin-top: 5vh !important;
  }

  .lIghthousesc4 {
    width: 30vw;
    top: 0;
  }

  .qrCode{
    width: 15vw;
    top: 0;
  }
  .qrText{
    top: 2%;
  }
}

@media only screen and (max-width: 600px) {
  
  .lIghthousesc4 {
    width: 30vw;
    top: 0;
    margin: 0 0 0 auto;
  }
  .qrCode {
    width: 15vw;
    top: 0;
    left: 0;
  }
  .qrText {
    top: 2%;
    left: 0;
    font-size: .95rem;
  }
  .formWrapper {
    width: 80vw;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .lIghthousesc4 {
    width: 25vw;
    top: 10%;
  }
  .qrCode{
    top: 10%;
  }
  .qrText{
    top: 15%;
  }
}