.iframe{
    height: 90vh;
    width: 90vw;
}

.howTo {
    width: 20vw;
}

.scroll {
    overflow: scroll;
    overflow-x: hidden;
    background-color: white;
    width: 90vw;
}

.img {
    width: 90vw;
}

.header {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    border-radius: 20px 20px 0 0;
}

.button {
    background-color: #f6921e;
    color: white;
    border: none;
    padding: 2%;
    border-radius: 20px 0 0 0;
    width: 25vw;
    height: inherit;
}

.close {
    background-color: black;
    width: min-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1%;
    font-size: larger;
    color: white;
    cursor: pointer;
    border-radius: 0 20px 0 0;
    &:hover {
        background-color: red;
    }
}