.thumbnailHeader {
    background-color: #8ab58c;
    padding-top: 5px;
    height: 40px;
    border-right: 10px solid black;
    border-left: 10px solid black;
}

.thumbnailContainer {
    margin-bottom: 25px;
}

.thumbnailText {
    padding-top: 5px;
    padding-left: 25px;
    font-size: 12px;
    background-color: white;
    width: 100%;
    text-align: left;
    height: 100%;
    padding-bottom: 10px;
}

.videoContainer {
    outline: none;
    height: 256px;
    object-fit: cover;
}

.videoContainerLarge {
    outline: none;
    height: 100%;
    object-fit: cover;
}

.headerText {
    padding-top: 5px;
    padding-left: 25px;
    color: white;
    text-align: left;
    font-size: 1.5rem;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #1a271c;
}

.borderEnabled {
    background-color: black;
    border: 10px solid #1a271c;
    padding: 0 !important;
    z-index: 9;
}

.closeModal {
    color: black;
    background-color: white;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 0.6%;
    cursor: pointer;
    border-radius: 10px;
        &:hover {
            background-color: red;
            color: white;
        }
}

.shareButton {
    text-align: left;
    padding-left: 15px;
    background-color: #1c271d;
    color: white;
    font-size: 1.5rem;
}

.shareButtonLink {
    color: white;
}

.shareButtonLink:link {
    color: white;
}

.shareButtonLink:visited {
    color: white;
}

.shareButtonLink:hover {
    color: white;
}

.shareButtonLink:focus {
    color: white;
}

.shareButtonLink:active {
    color: white;
}

.outerContainer {
    width: 100%;
    height: 100%;
    text-align: center;
}

.innerContainer {
    display: inline-block;
    position: relative;
}

.videoOverlay {
    position: absolute;
    width: 90%;
    height: 90%;
    left: 0;
    top: 0;
    margin: 25px;
    font-size: 20px;
    color: #fff;
}

.videoOverlayHidden {
    display: none;
}

.imageContainer {
    float: left;
    padding: 10px;
}
