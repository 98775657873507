
.navbar {
    position: fixed;
    background-color: white;
    border-radius: 0;
    text-align: right;
    right: 0;
    top: 10vh;

}

.logo {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    margin: 0;
    
}

@media only screen and (max-width: 600px) {
    .navbar{
        width: 100vw;
        border: none;
        border-radius: 0;
        background-color: rgba(255, 255, 255, 0);
        top: 10vw;
    }

    .logo {
        position: absolute;
        z-index: 0;
        height: 30vh;
    }
}

.acc {
    height: 2em;
    border-radius: 50%;
    &::after{
        display: none;
    }
}

.noCaret ::after {
    display: none !important;
}


