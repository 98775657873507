.heading {
  text-align: center;
  font-family: "DM Serif Display", sans-serif !important;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
.content{
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: normal;
  text-align: left;
}
