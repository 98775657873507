.backToTop {
    position: fixed;
    bottom: 1.87rem;
    right: 1.5rem;
    cursor: pointer;
    border: 0 solid white;
    border-radius: 100%;
    box-shadow: 0 0 10px 3px #ccc;
    padding: 0;
    z-index: 9999;
}

.arrow {
    width: 4.3rem;
    height: auto
}

.backToTop:hover {
    background: #d3ecd6;
}

@media only screen and (max-width: 600px) {
    .arrow {
        width: 3.5rem;
    }
}