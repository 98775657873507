.homeSectionOne {
    position: relative;
}

.mainText {
    font-size: 2em;
    padding: 1.3rem 2.5rem;
    margin-top: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 11rem;
    font-weight: bold;
}

.subTitle {
    @extend .mainText;
    font-size: 1.5em;
    margin-top: unset;
    font-weight: 500;
    line-height: 2rem;
}

.subTitleWrapper {
    margin-top: 0;
}

.centerMainText {
    position: relative;
    top: 50%;
}

.getStarted {
    @extend .mainText;
    font-family: 'DM Serif Display', sans-serif !important;
    text-align: center;
    font-weight: normal;
    margin: 17vh 0 2vh 0;
}

.img_GooglePlay, .img_Appstore {
    min-width: 120px;
    max-width: 150px;
    width: 10vw;
    height: auto;
}


.img_indigenous_Remastered {
    position: absolute;
    height: auto;
    width: 30vw;
    top: 15%;
    left: -5%;
    opacity: 0.15;
    z-index: -1;
}

.img_MuseumGalleries {
    @extend .img_indigenous_Remastered;
    top: 30%;
    left: 80%;
}

.container_logo{
    display: flex;
    margin: 60px auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 60%;
  
}
.text_award{
    @extend .mainText;
    margin-left: 0;
    width: 60%;
}

.img_award{
    width: 300px;
    height: 250px;
}
@media only screen and (max-width: 576px) {
    .homeSectionOne{
        margin-top: 3vh;
    }
    .mainText {
        margin-left: unset;
        font-size: 1.3rem;
        padding: 1rem;
        margin-top: 1rem;
    }
    .subTitle {
        font-size: 1.15rem;
        line-height: unset;
        margin-top: 5vh;
    }
   
    .img_award{
        width: 150px;
        height: 150px;
    }
    .text_award{
        width: 100%
    }
   
}

@media screen and (min-width: 900px) and (max-width: 1400px) {
    .homeSectionOne{
        margin-top: 7.5vh;
    }
    .mainText {
        margin-left: 1.3rem;
        font-size: 1.87rem;
    }
    .subTitle {
        font-size: 1.6rem;
        margin-top: 2vh;
    }
    .getStarted {
        margin: 13vh 0 2vh 0;
    }
}
@media screen and (min-width: 900px) and (max-width: 991px) {
    .subTitle {
        margin-top: 8vh;
    }
}

@media screen and (min-width: 767px) and (max-width: 900px) {
    .mainText {
        margin-left: 1.3rem;
        font-size: 1.87rem;
        //padding: 2rem 0 2rem 1.3rem;
    }
    .subTitle {
        font-size: 1.6rem;
        margin-top: 15vh;
    }
    .text_award{
        width: 100%
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .mainText {
        margin-left: 0;
        font-size: 1.6rem;
    }
    .subTitle {
        font-size: 1.35rem;
        margin-top: 7vh;
    }
    .img_award{
        width: 200px;
        height: 200px;
    }
    .text_award{
        width: 100%
    }
}

@media screen and (min-width: 500px) and (max-width: 576px) {
    .mainText {
        font-size: 1.5rem;
        margin-top: 10vh;
    }
    .subTitle {
        font-size: 1.3rem;
        margin-top: 10vh;
    }
}
@media screen and (min-width: 420px) and (max-width: 470px) {
    .mainText {
        margin-top: 5vh;
    }
    .subTitle {
        margin-top: 5vh;
    }
}
@media screen and (min-width: 470px) and (max-width: 499px) {
    .mainText {
        margin-top: 10vh;
    }
    .subTitle {
        margin-top: 5vh;
    }
}

@media only screen and (max-width: 1000px) {
    .getStarted {
        margin: 0 0 2vh 0;
    }
    .img_GooglePlay, .img_Appstore {
        width: 20vw;
        height: auto;
    }

    .img_MuseumGalleries {
        top: 40%;
    }
}

@media only screen and (min-width: 1600px) {
    .getStarted {
        margin: 30vh 0 2vh 0;
    }
}