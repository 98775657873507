@font-face {
    font-family: 'DM Serif Display';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.woff')
            format('woff'),
        /* Safari, Android, iOS */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.svg#ArialRoundedMTBold')
            format('svg');
}

@font-face {
    font-family: 'DM Sans';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Sans/DMSans-Regular.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Sans/DMSans-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */ url('../fonts/DM_Sans/DMSans-Regular.woff')
            format('woff'),
        /* Safari, Android, iOS */ url('../fonts/DM_Sans/DMSans-Regular.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Sans/DMSans-Regular.svg#ArialRoundedMTBold')
            format('svg');
}

:root {
    --heading-font: 'DM Serif Display', Arial, Helvetica, sans-serif;
    --common-font: 'DM Sans', Arial, Helvetica, sans-serif;
}

.container {
    margin-top: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading {
    font-family: var(--heading-font) !important;
    font-size: 3vw;
    margin-bottom: 1.5vw
}

.subHeading {
    @extend .heading;
    font-size: 1.8vw;
    text-align: center;
    color: black;

}

.textConatiner{
    width: 35vw;
}

.text {
    font-size: 1.2vw;
    line-height: 1.3vw;
    font-family: var(--common-font) !important;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
    text-align: left;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10vh;
}

.footerText{
    @extend .subHeading;
    text-decoration: none;
    margin-left: 4vw;
    margin-right: 4vw;
    color: black;

}

.img {
    width: 35vw;
    height: auto;
}

@media only screen and (max-width: 600px) {

    .textConatiner{
        width: 80vw;
    }

    .img {
        width: 80vw;
        height: auto;
    }

    .text {
        font-size: 2.5vw;
        line-height: 2.6vw;
        margin-top: 5vw;
        margin-bottom: 5vw;
    }

    .heading {
        font-size: 5vw;
    }

}