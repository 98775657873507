.heading {
    text-align: left;
    font-family: 'DM Serif Display', sans-serif !important;
    font-size: 2rem;
}

.content {
    text-align: left;
    padding: 0 9rem 0 0;
}

.followUs {
    @extend .heading;
    text-align: right;
    margin-right: 2%;
}

.lovestories_Remastered {
    width: 30vw;
    height: auto;
    opacity: 0.15;
    position: absolute;
    bottom: 50%;
    left: -10%;
}

.hatPersonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.WHY05027 {
    width: 30vw;
    height: auto;
    margin-right: -5%;
}

.grandmaOrangeFlowers {
    @extend .WHY05027;
    margin: 0 0 0 -10%;
}

.acknowledgement {
    padding-right: 10rem;
}

.socialLogos {
    position: relative;
    top: 30%;
    text-align: right;
}

.socialLogos img {
    width: 10.5%;
    display: inline-block;
}

.imgArts {
    @extend .lovestories_Remastered;
    width: 45vw;
    top: 50%;
    right: -15%;
    bottom: unset;
    left: unset;
}

@media only screen and (max-width: 1000px) {
    .heading {
        font-size: 1.5rem;
        text-align: center;
    }

    .content {
        text-align: left;
        padding: 1rem 2rem 0 2rem;
    }

    .followUs {
        font-size: 1.5rem;
        text-align: right;
    }
    .lovestories_Remastered {
        top: 20%;
    }
    .hatPersonWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .WHY05027 {
        width: 40vw;
        height: auto;
        display: block;
        left: 0;
        top: 10%;
        margin-right: unset;
    }
    .socialLogos img {
        width: 20%;
    }
    .grandmaOrangeFlowers {
        margin: 0 0 0 -20%;
    }

    .acknowledgement {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        align-items: center;
    }
    .imgArts {
        top: 40%;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1400px) {
    .content {
        text-align: left;
        padding: 0 2rem 0 0;
    }
    .grandmaOrangeFlowers {
        margin: 0 0 0 -10%;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .WHY05027 {
        width: 35vw;
        height: auto;
        margin-right: -5%;
    }
}

@media only screen and (max-width: 768px) {
    .lovestories_Remastered {
        top: 15%;
    }
    .imgArts {
        top: 40%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1001px) {
    .WHY05027 {
        margin-right: -30%;
    }
    .acknowledgement {
        margin-left: -15%;
        padding-right: 2rem;
    }
    .socialLogos img {
        width: 10%;
    }
}
