.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    background-color: #ffd230;
    width: 50vw;
    border-style: none;
    border-radius:28px;
    padding: 1.5vw;
    margin-top: 1vw;

}



.container {
    width: 50vw;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container2 {
    width: 25vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.appBox {
    width: 25vw;
    height: 25vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.app {
    width: 12vw;
    height: 12vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.phone {
    width: 50%;
    height: auto;
}

.img {
    height: 12vw;
}

.imgApp {
    width: 12vw;
}