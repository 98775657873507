@font-face {
    font-family: 'DM Sans';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Sans/DMSans-Regular.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Sans/DMSans-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */ url('../fonts/DM_Sans/DMSans-Regular.woff')
            format('woff'),
        /* Safari, Android, iOS */ url('../fonts/DM_Sans/DMSans-Regular.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Sans/DMSans-Regular.svg#ArialRoundedMTBold')
            format('svg');
}

:root {
    --common-font: 'DM Sans', Arial, Helvetica, sans-serif;
}

.container {
    width: 50vw;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.app {
    width: 20vw;
}

.img {
    width: 50vw;
    margin: auto;
}

.container2{
    text-align: center;
}

.text {
    font-family: var(--common-font) !important;
}
.emailButton {
    margin: 0 auto;
    padding: 10px 20px;
    border-radius: 10px;
    width: max-content;
    background-color: #d3ead5;
    border: none
}
.emailButton a {
    color: black;
    text-decoration: none;
}
.emailButton:hover{
    background-color: #a8b3a9;
}