.hoveringVideo {
    text-align: center;
    background-color: white;
    margin: -455px auto;
    border: 10px green;
    width: 450px;
}
.mapCaptionWrapper{
    display: flex;
    padding: 1.3rem;
    position: absolute;
    left: -20%;
}
.mapCaption {
    text-align: center;
    padding: 1.3rem .61rem;
    font-size: 1.2rem;
}

@media screen and (max-width: 450px) {
    .hoveringVideo {
        text-align: center;
        background-color: white;
        margin: -455px auto;
        border: 10px green;
        width: 90%;
    }
    .mapCaption {
        font-size: 1rem;
    }
}

.clickableMarker {
    margin-left: -20px;
    margin-top: -20px;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.categoryImage {
    height: 50px;
}
