@font-face {
    font-family: 'DM Sans';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Sans/DMSans-Regular.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Sans/DMSans-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */ url('../fonts/DM_Sans/DMSans-Regular.woff')
            format('woff'),
        /* Safari, Android, iOS */ url('../fonts/DM_Sans/DMSans-Regular.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Sans/DMSans-Regular.svg#ArialRoundedMTBold')
            format('svg');
}
@font-face {
    font-family: 'DM Serif Display';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.woff')
            format('woff'),
        /* Safari, Android, iOS */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.svg#ArialRoundedMTBold')
            format('svg');
}

@font-face {
    font-family: 'DM Serif Bold';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Sans/DMSans-Bold.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Sans/DMSans-Bold.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */
            url('../fonts/DM_Sans/DMSans-Bold.woff')
            format('woff'),
        /* Safari, Android, iOS */
            url('../fonts/DM_Sans/DMSans-Bold.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Sans/DMSans-Bold.svg#ArialRoundedMTBold')
            format('svg');
}

:root {
    --common-font: 'DM Sans', Arial, Helvetica, sans-serif;
    --heading-font: 'DM Serif Display', Arial, Helvetica, sans-serif;
    --bold-font: 'DM Serif Bold', Arial, Helvetica, sans-serif;
}
.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 50vw;
    margin:50px auto auto auto;
}

.img{
    object-fit: contain;
    width: 23vw;
    margin: 1vw;
    height: auto;
}

.section2 {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.cameraStudent {
    margin: auto;
    padding: 2vh;
    width: 50vw;

}

.text {
    font-family: var(--common-font) !important;
}

.heading {
    font-family: var(--heading-font) !important;

}
.emailButton {
    margin: 0 auto;
    padding: 10px 20px;
    border-radius: 10px;
    width: max-content;
    background-color: #d3ead5;
    border: none
}
.emailButton a {
    color: black;
    text-decoration: none;
}
.emailButton:hover{
    background-color: #a8b3a9;
}

@media only screen and (max-width: 600px) {
    .container {
        flex-direction: column;
    }

    .img {
        width: 50vw;
    }

}
.link {
    margin-bottom: 25vh;
}

@media only screen and (max-width: 600px) {
    .link {
        margin-bottom: 0vh;
    }

}