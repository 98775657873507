@font-face {
    font-family: 'DM Sans';
    /* IE9 Compat Modes */
    src: url('./fonts/DM_Sans/DMSans-Regular.eot');
    /* IE6-IE8 */
    src: url('./fonts/DM_Sans/DMSans-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */ url('./fonts/DM_Sans/DMSans-Regular.woff')
            format('woff'),
        /* Safari, Android, iOS */ url('./fonts/DM_Sans/DMSans-Regular.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('./fonts/DM_Sans/DMSans-Regular.svg#ArialRoundedMTBold')
            format('svg');
}

footer * {
    font-family: 'DM Sans', sans-serif !important;
    font-weight: normal;
}

.footerWrapper {
    background-color: black;
    padding-top: 30px;
}

.patreonText {
    color: white;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    margin: 10px;
    font-size: 1.2rem;
    text-align: center;
}

.patreonButton {
    margin: 0 auto;
    padding: 5px 10px;
    border-radius: 10px;
    width: max-content;
    background-color: #d3ead5;
    border: none
}
.patreonButton a {
    color: black;
    text-decoration: none;
}

.footerLogoWrapper {
    display: flex;
    justify-content: flex-end;
    margin: 0 2rem;
}

.footerSponsorWrapper {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 2rem;
    padding: 0;
    color: gray;
}

.footerSponsorLogoWrapper
{
    display:flex;
    justify-content: space-around;
    margin-bottom: 1vw;
    width: auto;
}

.footerStyle {
    position: relative;
    text-align: center;
    background-color: black;
    bottom: 0;
    color: white;
    padding: 0.5% 0 3% 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 90vw;
    letter-spacing: 0px;
}
.footerRow1 {
    display: flex;
    height: 20vh;
    justify-content:flex-start;
    flex-direction: row;
    width: 100%;
}
.footerLogo {
    width: 8vw;
}

.sponsorLogo {
    width: 8vw;
    margin: 0;
}
.footerLinkCol1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    margin: 0 2rem;
}
.footerLinkCol1 a,
.footerLinkCol2 a {
    color: white;
    text-decoration: none;
    text-align: left;
}

.footerLinkCol1 > a:hover,
.footerLinkCol2 > a:hover {
    color: #d3ecd6;
    text-decoration: none;
}
.footerLinkCol2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 2rem;
}
.lastLine {
    color: #d3ecd6a8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lastLine p{
    margin-bottom: 0;
}

.lastLine a {
    text-decoration: none;
    color: #d3ecd6a8;
}

.lastLine a:hover {
    text-decoration: underline;
    color: white;
}

@media screen and (max-width: 600px) {

    .patreonText {
        font-size: 0.8rem;
    }

    .sponsorLogo
    {
        width: 15vw;
    }

    .footerSponsorWrapper
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .footerStyle {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    .footerLinkCol1 {
        align-items: center;
        text-align: left;
        justify-content: space-between;
    }
    .footerLinkCol2 {
        align-items: center;
        text-align: left;
        justify-content: space-between;
    }
    .footerLinkCol1 > *,
    .footerLinkCol2 > * {
        padding-top: 0.5vh;
    }
    .footerRow1 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        flex-direction: column;
        height: auto;
        margin-bottom: 5%;
    }
    .footerLogo {
        width: 50vw;
    }
}
