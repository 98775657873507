  .carousel-item-padding {
    padding: 10px;
  }
  
  .video-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    
  }
  .video-wrapper p{
    text-align: center;
  }
  
  .video-item {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: cover;
    padding: 10px;
  }
  
  .carousel-arrow {
    position: absolute;
    top: 37%; 
    transform: translateY(-50%); 
    z-index: 2; 
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 3rem;
  }
  
  .carousel-arrow--left {
    left: 31px; 
  }
  
  .carousel-arrow--right {
    right: 31px; 
  }
  
  @media (max-width: 768px) {
    .video-item {
      width: 90%;
    }
    .carousel-arrow {
      top:30%
    }
  
    .carousel-arrow--left {
      left: 40px; 
    }
    
    .carousel-arrow--right {
      right: 40px; 
    }
  }
  