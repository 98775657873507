@font-face {
    font-family: 'DM Sans';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Sans/DMSans-Regular.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Sans/DMSans-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */ url('../fonts/DM_Sans/DMSans-Regular.woff')
            format('woff'),
        /* Safari, Android, iOS */ url('../fonts/DM_Sans/DMSans-Regular.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Sans/DMSans-Regular.svg#ArialRoundedMTBold')
            format('svg');
}
@font-face {
    font-family: 'DM Serif Display';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.woff')
            format('woff'),
        /* Safari, Android, iOS */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.svg#ArialRoundedMTBold')
            format('svg');
}

@font-face {
    font-family: 'DM Serif Bold';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Sans/DMSans-Bold.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Sans/DMSans-Bold.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */
            url('../fonts/DM_Sans/DMSans-Bold.woff')
            format('woff'),
        /* Safari, Android, iOS */
            url('../fonts/DM_Sans/DMSans-Bold.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Sans/DMSans-Bold.svg#ArialRoundedMTBold')
            format('svg');
}

:root {
    --common-font: 'DM Sans', Arial, Helvetica, sans-serif;
    --heading-font: 'DM Serif Display', Arial, Helvetica, sans-serif;
    --bold-font: 'DM Serif Bold', Arial, Helvetica, sans-serif;
}

.box {
    @extend .container;
    background-color: #ffd230;
    width: 20vw;
    height: 20vw;
    justify-content: space-between;
    padding: 1vw;
    text-align: center;
    margin: 0;
}

.qr {
    width: 7vw;
}

.app {
    width: 15vw;
    margin: auto;
}

.appBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5vw;

}

.text {
    width: 50vw;
    font-family: var(--common-font) !important;
}

.heading {
    font-family: var(--heading-font) !important;

}

.patreonText {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin: 10px;
  font-size: 1.2rem;
  text-align: center;

  @media only screen and (max-width: 768px) {
    margin: 5rem;
  }

  @media only screen and (max-width: 640px){
    margin: 2rem;
  }
}

.patreonButton {
  margin: 0 auto;
  padding: 5px 10px;
  border-radius: 10px;
  width: max-content;
  background-color: #d3ead5;
  border: none
}
.patreonButton a {
  color: black;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .appBox {
        flex-direction: column;
    }

    .box {
        width: 60vw;
        height: 60vw;
        padding: 3vw;
    }

    .app {
        width: 55vw;
    }

    .qr {
        width: 30vw;
    }
}

.link {
    margin-bottom: 25vh;
}

@media only screen and (max-width: 600px) {
    .link {
        margin-bottom: 0vh;
    }

}