.background {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999999999;
    backdrop-filter: blur(10px);
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    background-color: white;
    border-radius: 25px;
    width: 50%;
    height: min-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;
}

.close {
    align-self: flex-end;
    background-color: black;
    display: inline-block;
    width: min-content;
    padding: 1%;
    color: white;
    cursor: pointer;
    border-radius: 0 25% 0 0;
    &:hover {
        background-color: red;
    }
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-weight: bold;

}

.login input{
    border: none;
    border-radius: 5px;
    border: 1px solid black;
    padding: 1%;
    padding-left: 3%;
    width: 25vw;
}

.signup {
    @extend .login;
}

.button {
    background-color: #f6921e;
    color: white;
    border: none;
    padding: 2%;
    border-radius: 5px;
    width: 25vw;
    cursor: pointer;
}

.disabled
{
    @extend .button;
    background-color: #f6911e83;
    cursor: not-allowed
}

.option {
    @extend .button;
    cursor: pointer;
}

.error {
    width: 80%;
    background-color: lightcoral;
}

.wellcome {
    position: fixed;
    top: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    width: 100vw;
}

.logo{
    width: 20%;
    margin-top: 0;

}