.heading {
    text-align: center;
    font-family: "DM Serif Display", sans-serif !important;
    font-size: 2rem;
    margin: 1.3rem 0;
}

.logoSlider {
    $min-image-size: 50px;
    $max-image-size: 100px;

    --image-size: #{$max-image-size};

    padding: 20px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;

    &:hover div {
        animation-play-state: paused;
    }

    .logoWrapper1 {
        display: flex;
        position: relative;
        animation: marquee1 40s linear infinite;
        justify-content: space-around;
        align-items: center;
        left: 0;
        top: 0;
        margin-top: 2%;
        * {
            display: block;
            margin: 0 15px;
            width: auto;
            height: auto;
            min-height: 8vh;
            max-height: 10vh;
        }
    }
    .logoWrapper2 {
        @extend .logoWrapper1;
        animation: marquee2 40s linear infinite;
    }

    img {
        display: block;
        min-width: var(--image-size);
        height: var(--image-size);
        margin: 0 1vw;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(
            to right,
            white,
            rgba(white, 0) 80px,
            rgba(white, 0) calc(100% - 80px),
            white
        );
    }

    @media (max-width: 900px) {
      --image-size: min(max(#{$min-image-size}, 10vw), #{$max-image-size});

        .logoWrapper1 {
            * {
                min-height: 7vh;
                max-height: 8vh;
            }
        }
    }
}

@keyframes marquee1 {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes marquee2 {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@media only screen and (max-width: 1000px) {
  .heading{
    font-size: 1.5rem;
  }
}
