@font-face {
  font-family: 'arial-rounded-bold';
  /* IE9 Compat Modes */
  src: url('./fonts/arial-rounded-mt-bold/ArialRoundedMTBold.eot');
  /* IE6-IE8 */
  src: url('./fonts/arial-rounded-mt-bold/ArialRoundedMTBold.eot?#iefix') format('embedded-opentype'),
    /* Modern Browsers */
    url('./fonts/arial-rounded-mt-bold/ArialRoundedMTBold.woff') format('woff'),
    /* Safari, Android, iOS */
    url('./fonts/arial-rounded-mt-bold/ArialRoundedMTBold.ttf') format('truetype'),
    /* Legacy iOS */
    url('./fonts/arial-rounded-mt-bold/ArialRoundedMTBold.svg#ArialRoundedMTBold') format('svg');
}

@font-face {
  font-family: 'arial-mt';
  /* IE9 Compat Modes */
  src: url('./fonts/arial/ArialMT.eot');
  /* IE6-IE8 */
  src: url('./fonts/arial/ArialMT.eot?#iefix') format('embedded-opentype'),
    /* Modern Browsers */
    url('./fonts/arial/ArialMT.woff') format('woff'),
    /* Safari, Android, iOS */
    url('./fonts/arial/ArialMT.ttf') format('truetype'),
    /* Legacy iOS */
    url('./fonts/arial/ArialMT.svg#ArialMT') format('svg');
}

@font-face {
  font-family: 'DM Sans';
  /* IE9 Compat Modes */
  src: url('./fonts/DM_Sans/DMSans-Regular.eot');
  /* IE6-IE8 */
  src: url('./fonts/DM_Sans/DMSans-Regular.eot?#iefix') format('embedded-opentype'),
    /* Modern Browsers */
    url('./fonts/DM_Sans/DMSans-Regular.woff') format('woff'),
    /* Safari, Android, iOS */
    url('./fonts/DM_Sans/DMSans-Regular.ttf') format('truetype'),
    /* Legacy iOS */
    url('./fonts/DM_Sans/DMSans-Regular.svg#ArialRoundedMTBold') format('svg');
}

:root {
  --common-font: 'DM Sans', Arial, Helvetica, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  font-family: 'DM Sans', "Arial", sans-serif !important;
  /* font-weight: 400; */
  /* display:block; */
}

html {
  font-size: 16px;
}

body {
  overflow-x: hidden;
}

.navHeader {
  position: relative;
  /* width:100%; */
  /* height:100vh; */
}

.navHeader img {
  display: block;
}

.navHeader #basic-navbar-nav {
  margin-top: -5%;
}

.linkNavHeader img {
  display: block;
}

.navHeader a, .navHeader a:hover,
.navHeadera a:focus, .navHeader a:active {
  text-decoration: none;
}

.logo {
  height: 30vh;
  display: block;
}

.navContainer {
  align-items: flex-start !important;
}

.navContainer * {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: bold;
}

.linkText {
  color: black !important;
  font-size: 1.3rem;
  margin: 1rem;
  z-index: 1;
}

.linkText a {
  color: black !important;
}

.dropDownText {
  color: black !important;
  font-size: 1.25rem;
  margin: .63rem 0;
  z-index: 1;
}

.topHoveringVideo {
  text-align: center;
  background-color: white;
  margin: 0;
  position: absolute;
  border: 10px green;
  width: 450px;
}

.img_MuesumsGalleries {
  position: absolute;
  top: 40vw;
  right: -5vw;
  opacity: 0.15;
  z-index: -1;
  /* max-width:30vw; */
  height: 60vh;
  margin: auto;
}

.img_map {
  max-width: 100%;
  margin-top: 8%;
  display: block;
}

.HomeSectionOne {
  position: relative;
}

.HomeSectionOne2 {
  position: relative;
}

.HomeSectionOne3 {
  position: relative;
  margin-top: 5%;
}

.HomeSectionOne4 {
  position: relative;
  margin-bottom: 10%;
}

.center {
  text-align: center;
}

.OurCommunitiesRemastered {
  position: absolute;
  height: 60vh;
  margin-top: -7%;
  margin-left: -20%;
  z-index: -1;
  opacity: 0.15;
  /* max-width: 100%; */
}

.text {
  font-weight: bold;
  font-size: 2.5vw;
  vertical-align: middle;
}

.inbox {
  font-weight: bold;
  font-size: 1.5vw;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  margin-top: 5vh;
}

.email {
  background-color: gray !important;
}

.headerinbox {
  font-weight: bold;
  font-size: 3vw;
}

.headerinboxtext {
  font-weight: bold;
  font-size: 2.5vw;
}

.prsnName {
  text-align: center;
}

.headerTestimonial {
  font-weight: bold;
  text-align: center;
  margin-bottom: 3vh;
}

.headerAcknowledgement {
  font-weight: bold;
  text-align: left;
}

.txtTestimonial {
  border-radius: 25px;
  font-size: 1.5vw;
  vertical-align: middle;
  border: 1px solid;
  padding: 10%;
  margin: 5%;
}

.Acknowledgement {
  position: relative;
  font-size: 1.5vw;
  vertical-align: middle;
  text-align: justify;
  width: 80%;
  margin: 5% 0 0 65%;
  padding: 0 14rem 0 0;
}

.secondary-text {
  font-weight: normal;
  line-height: normal;
  font-size: 2vw;
  padding: 0 10px;
  vertical-align: middle;
}

.MungoRangertrans {
  position: absolute;
  left: 45%;
  top: -6.5%;
  width: 30vw;
  height: 95vh;
  z-index: 0;
  cursor: pointer;
}

.MapWithAppIcons {
  position: absolute;
  left: 62%;
  top: -3.5%;
  width: 27vw;
  height: 80vh;
  z-index: -1;
}



.headersocialLogos {
  font-weight: bold;
  text-align: right;
  font-size: 2.5vw;
}


.left {
  margin-left: 4%;
}

.text2 {
  margin-top: 50%;
}

.text3 {
  margin-top: 65%;
  margin-right: -30%;
}

.ParanormalRemastered {
  position: absolute;
  /* width: 40vw; */
  height: 50vh;
  bottom: 0%;
  right: -6%;
  opacity: 0.15;
}


.indigenous_Remastered {
  position: absolute;
  bottom: 7%;
  /* top:60%; */
  /* bottom: -85%; */
  left: 15%;
}



.lovestories_Remastered {
  height: 60vh;
  opacity: 0.15;
  position: absolute;
  bottom: 50%;
  left: -10%;

}

.PoliticalRemastered {
  /* width:30vw; */
  height: 70vh;
  opacity: 0.15;
  position: absolute;
  /* bottom: 50%; */
  left: -15%;
}

.WHY05027 {
  position: absolute;
  /* top: 57%; */
  left: 15%;
  height: 90vh;
}

.copyright {
  color: rgb(218, 218, 218);
  font-size: 1.4rem;
  position: relative;
  margin-top: 1%;
}

ul.a {
  list-style-type: none;
  position: relative;
  /* margin-top:10%; */
  margin-top: 35%;
  /* margin-right:2%; */
}

ul.b {
  position: relative;
  margin-top: 10%;
  list-style-type: none;
  margin-left: -200%;
  margin-top: 35%;
}

.primary-text {
  font-size: 3vw;
  font-weight: normal;
  line-height: normal;
}

.common-text {
  font-family: var(--common-font) !important;
  font-weight: normal;
}

.form-wrapper {
  padding: 2vw 3vw;
  background-color: #d3ecd6;
  border-radius: 20px;
}

.divTestimonial {
  margin-top: 5vh;
}




.ancorslogo {
  margin-top: 1%;
}


.subscribe-btn {
  border: none;
  padding: 1vw;
  border-radius: 4px;
  margin-top: 2vh;
}

.qrcode-section {
  margin-top: 30vh !important;
}

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {

  /* asd */
  html {
    overflow-x: hidden;
    max-width: 100%;
    scroll-behavior: smooth;
  }

  .subscribe-btn {
    border: none;
    background-color: transparent;
  }

  .ancorslogo {
    margin-top: 5%;
  }


  .logo {
    width: 50vw;
    height: 20vh;
    margin: 0 0 10px 10%;
  }

  .MungoRangertrans {
    position: absolute;
    left: 45%;
    top: 2%;
    width: 40vw;
    height: auto;
    z-index: 0;
  }

  .MapWithAppIcons {
    position: absolute;
    left: 69%;
    top: 4%;
    width: 35vw;
    height: auto;
    z-index: -1;
  }

  .divTestimonial {
    position: relative;
    /* margin-top:-80%; */
  }

  .topHoveringVideo {
    text-align: center;
    background-color: white;
    border: 10px green;
    margin: 0px;
    position: absolute;
    width: 100vw;
    left: 0vw;
    padding: 1rem;
  }

  .MuesumsGalleries {
    position: absolute;
    position: absolute;
    max-width: 10vw;
    height: 10vh;
    top: 50%;
    right: 10%;
  }

  .indigenous_Remastered {
    position: absolute;
    top: 57%;
    left: 35%;
    height: 10vh;
    width: 10vw;
  }


  .imgSitesLandmarksRemaster {
    position: absolute;
    top: 58%;
    left: 20%;
    height: 10vh;
    width: 10vw;
  }

  .left {
    margin-left: 20%;
  }

  .img_MuesumsGalleries {
    position: absolute;
    top: 60vw;
    right: -35vw;
    opacity: 0.15;
    z-index: -1;
    /* max-width: 30vw; */
    height: 30vh;
    margin: auto;
    /* max-width: 100%; */
    /* margin-bottom: 0; */
  }

  .header-top-rigth-img {
    width: 30vw;
    height: 30vh;
    position: absolute;
    top: -10%;
    right: 1vw;
    opacity: 0.15;
    z-index: -1;
    max-width: 100%;
  }

  .linkNavHeader {
    z-index: 1;
  }



  .text3 {
    position: relative;
    margin-right: 50%;
    bottom: 25%;
  }

  .text2 {
    margin-top: -55%;
    margin-left: 35%;
    text-align: center;
  }

  .lovestories_Remastered {
    position: absolute;
    height: 30vh;
    margin-left: -10%;
    top: 0%;
    /* left: -120%; */
    opacity: 0.15;
  }

  .WHY05027 {
    height: 30vh;
    display: block;
    left: 0%;
    top: 10%;
  }

  .Acknowledgement {
    position: relative;
    display: block;
    /* max-width: 100%; */
    margin-left: 40%;
    font-size: 0.5rem;
    max-width: 100%;
  }

  .headerAcknowledgement {
    font-size: 0.5rem;
  }

  .inbox {
    font-size: 1rem;
  }

  .PoliticalRemastered {
    height: 30vh;
    opacity: 0.15;
    position: absolute;
    left: -25%;
  }

  .ParanormalRemastered {
    position: absolute;
    height: 30vh;
    bottom: -30%;
    right: -27%;
    opacity: .15;
  }

  .copyright {
    color: white;
    font-size: 1rem;
    position: relative;
    /* margin-top:1%; */
    left: 5%;
  }

  ul.a {
    list-style-type: none;
    position: relative;
    margin-top: -20%;
    /* margin-top:35%; */
    /* margin-right:2%; */
  }

  ul.b {
    position: relative;
    margin-top: -22%;
    list-style-type: none;
    margin-left: 60%;
    /* margin-top:35%; */
  }

  .ms-auto {
    align-content: center;
    align-items: center;
    border-radius: 4px;
    width: 50%;
    float: right;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }

  .navContainer {
    align-items: center !important;
  }

  .linkText {
    font-size: .82rem;
    margin: .63rem auto;
  }

  .dropDownText {
    color: black !important;
    font-size: .82rem;
    margin: .63rem auto;
    z-index: 1;
  }

  /* asd */
  .primary-text {
    font-size: 5vw;
    font-weight: normal;
    line-height: 3vh;
    text-align: center;
  }

  .secondary-text {
    font-weight: normal;
    line-height: 1em;
    font-size: 5vw;
    padding: 0 1px;
  }

  .subscribe-btn {
    border: none;
    padding: 2vw;
    border-radius: 4px;
    margin-top: 2vh;
  }

  .common-text {
    font-family: var(--common-font);
    font-size: 3.5vw;
    font-weight: normal;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  body {
    overflow-x: hidden;
  }
  .MungoRangertrans {
    width: 30vw;
    height: auto;
    z-index: 0;
    cursor: pointer;
  }

  .MapWithAppIcons {
    width: 25vw;
    height: auto;
    z-index: -1;
  }

  .header-top-rigth-img {
    height: 60vh;
    position: absolute;
    top: -10vw;
    /* top: -350px; */
    right: -10vw;
    opacity: 0.15;
    z-index: -1;
    max-width: 100%;

  }


}

@media screen and (min-width: 900px) and (max-width: 1000px){
  .navContainer{
    align-items: center !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 991px){
  .ms-auto {
    align-content: center;
    align-items: center;
    border-radius: 4px;
    width: 50%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
}

@media screen and (max-width: 900px) and (min-width: 600px) {

  .MungoRangertrans {
    position: absolute;
    left: 45%;
    top: -2%;
    width: 35vw;
    height: auto;
    z-index: 0;
    cursor: pointer;
  }

  .MapWithAppIcons {
    position: absolute;
    left: 63%;
    top: 0;
    width: 30vw;
    height: auto;
    z-index: -1;
  }

  .text2 {
    /* margin-top: -70%; */
    margin-left: 50%;
  }

  .ms-auto {
    align-content: center;
    align-items: center;
    border-radius: 4px;
    width: 50%;
    float: right;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }

  .navContainer {
    align-items: center !important;
  }

  .linkText {
    font-size: .94rem;
    margin: .63rem auto;
  }

  .dropDownText {
    color: black !important;
    font-size: .94rem;
    margin: .63rem auto;
    z-index: 1;
  }
  .topHoveringVideo {
    text-align: center;
    background-color: white;
    border: 10px green;
    margin: 0;
    position: absolute;
    width: 450px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .header-top-rigth-img {
    height: 20vh;
    width: 20vh;
    position: absolute;
    top: -8vw;
    /* top: -350px; */
    right: -10vw;
    opacity: 0.15;
    z-index: -1;
    max-width: 60vw;

  }

}

@media only screen and (min-width: 990px) and (max-width: 1199px){
  .MungoRangertrans {
    width: 40vw;
  }

  .MapWithAppIcons {
    width: 35vw;
  }
}