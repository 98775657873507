.mainText {
    font-size: 2rem;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: bold;
}

.subTitle1 {
    @extend .mainText;
    font-size: 1.2rem;
    margin-top: unset;
    font-weight: bold;
    padding: unset;
    display: flex;
    align-items: center;
    width: 40%;
}

.subTitle2 {
    @extend .subTitle1;
}

.filmMaker {
    width: 30vw;
    height: auto;
    margin-right: -10%;
}

.haprers {
    width: 30vw;
    height: auto;
    margin-left: -10%;
    z-index: 5;
}

.ourCommunitiesRemastered {
    width: 30vw;
    height: auto;
    position: absolute;
    z-index: -1;
    opacity: 0.15;
    top: -7%;
    left: -12%;
}

.industryRemasteredBig {
    @extend .ourCommunitiesRemastered;
    top: 5%;
    left: 80%;
}

.sitesLandmarksRemaster {
    @extend .ourCommunitiesRemastered;
    top: 25%;
    left: -5%;
}

.majorEventsRemastered {
    @extend .ourCommunitiesRemastered;
    top: 55%;
    left: -20%;
}

.colonialRemasteredBig {
    @extend .ourCommunitiesRemastered;
    top: 75%;
    left: 20%;
}

.allStoriesRemastered {
    @extend .ourCommunitiesRemastered;
    width: 45vw;
    top: 40%;
    left: 75%;
}

.colonialRemasteredSmall {
    position: absolute;
    width: 80px;
    height: auto;
    z-index: -1;
    bottom: 6%;
    left: 4%;
}

.indigenousRemasteredSmall {
    @extend .colonialRemasteredSmall;
    bottom: 8%;
    left: 20%;
}

.imgSitesLandmarksRemaster {
    @extend .colonialRemasteredSmall;
    bottom: 10%;
    left: 35%;
}

.museumsGalleriesSmall {
    @extend .colonialRemasteredSmall;
    top: 72%;
    left: 75%;
    bottom: unset;
}

.industryRemasteredSmall {
    @extend .colonialRemasteredSmall;
    bottom: unset;
    left: unset;
    top: 72%;
    right: 10%;
}
.uploadContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.uploadTrail{
    @extend .uploadContainer;
    align-items: end;
}
.uploadButton {
    position: relative;
    left:9%;
    padding: 10px 20px;
    border-radius: 10px;
    width: max-content;
    background-color: #d3ead5;
    border: none
}
.uploadButton:hover{
    background-color: #a8b3a9;
}
.uploadButtonTrail{
    @extend .uploadButton;
    left:-17%;
}


@media only screen and (max-width: 1000px) {
    .mainText {
        font-size: 1.5rem;
        padding: .5rem;
        margin-left: unset;
    }
    .subTitle1 {
        font-size: 1.1rem;
        line-height: unset;
        padding: 0 0;
        width: 80%;
        margin-left: -10%;
    }
    .subTitle2 {
        padding: 0 1.3rem;
        width: 80%;
        margin-right: -10%;
    }
    .filmMaker {
        width: 50vw;
        height: auto;
        margin-right: -10%;
    }
    .haprers {
        width: 50vw;
        height: auto;
        margin-left: -10%;
    }
    .colonialRemasteredBig {
        top: 80%;
    }
    .allStoriesRemastered {
        width: 50vw;
        top: 45%;
        left: 70%;
    }
    .industryRemasteredSmall {
        position: absolute;
        top: 72%;
        right: 2%;
        z-index: -1;
    }
    .museumsGalleriesSmall {
        display: none;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
    .filmMaker,
    .haprers {
        width: 40vw;
    }
}

@media only screen and (max-width: 800px) {
    .mainText {
        font-size: 1.3rem;
    }
    .subTitle1,
    .subTitle2 {
        font-size: 1rem;
        width: 100%;
    }
    .subTitle2 {
        padding: 0;
    }
    .uploadButton {
      
        left:0%;
    }

}

@media only screen and (min-width: 1000px) and (max-width: 1400px) {
    .mainText {
        font-size: 1.6rem;
    }
    .subTitle1,
    .subTitle2 {
        font-size: 1.2rem;
        width: 50%;
        margin-left: 0;
    }
    .industryRemasteredSmall {
        right: 5%;
    }
   

}
