@font-face {
    font-family: 'DM Sans';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Sans/DMSans-Regular.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Sans/DMSans-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */ url('../fonts/DM_Sans/DMSans-Regular.woff')
            format('woff'),
        /* Safari, Android, iOS */ url('../fonts/DM_Sans/DMSans-Regular.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Sans/DMSans-Regular.svg#ArialRoundedMTBold')
            format('svg');
}
@font-face {
    font-family: 'DM Serif Display';
    /* IE9 Compat Modes */
    src: url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.eot');
    /* IE6-IE8 */
    src: url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* Modern Browsers */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.woff')
            format('woff'),
        /* Safari, Android, iOS */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf')
            format('truetype'),
        /* Legacy iOS */
            url('../fonts/DM_Serif_Display/DMSerifDisplay-Regular.svg#ArialRoundedMTBold')
            format('svg');
}

:root {
    --common-font: 'DM Sans', Arial, Helvetica, sans-serif;
    --heading-font: 'DM Serif Display', Arial, Helvetica, sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
}
.headingFont {
    font-family: var(--heading-font) !important;
}
.commonFont {
    font-family: var(--common-font) !important;
}
.heading {
    @extend .headingFont;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.cameramanFig {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.cameraman {
    width: 40vw;
}

.cameramanCaption {
    text-align: center;
    font-family: var(--common-font) !important;
    font-size: .9rem;
    margin-top: .5rem;
}
.faqs {
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    padding: 0 30vw;
    margin-top: 1rem;
}

.questions {
    @extend .headingFont;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.answers {
    @extend .commonFont;
    margin-bottom: 2rem;
}

.downloadButton {
    margin: 0 auto;
    padding: 10px 20px;
    border-radius: 10px;
    width: max-content;
    background-color: #d3ead5;
    border: none
}
.downloadButton a {
    color: black;
    text-decoration: none;
}

.handScreenFig {
    
    position: relative;
    right: 5vw;
    bottom: 0;
}
.handScreen {
    width: 30vw;
}

@media only screen and (max-width: 1200px) {
    .cameraman {
        width: 60vw;
    }
    .faqs {
        padding: 0 10rem;
    }
}

@media only screen and (max-width: 900px) {
    .cameraman {
        width: 60vw;
    }
    .faqs {
        padding: 0 4rem;
    }
}

@media only screen and (max-width: 768px) {
    .heading {
        font-size: 1.7rem;
    }
    .questions {
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 600px) {
    .heading {
        font-size: 1.5rem;
    }
    .cameraman {
        width: 100vw;
    }
    .faqs {
        padding: 0 1rem;
    }
    .questions {
        font-size: 1.1rem;
    }
    .handScreen {
        width: 80vw;
    }
}

.link {
    margin-bottom: 25vh;
}

@media only screen and (max-width: 600px) {
    .link {
        margin-bottom: 0vh;
    }

}
